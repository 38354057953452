<template>
  <div class="mailing-commercial">
    <b-button
      size="sm"
      class="button-export-style  ml-2 mr-2"
      @click="openModal"
      title="Envoyez un email"
    >
      <font-awesome-icon icon="envelope" />
    </b-button>
    <mail-vendeur
      @eventMailSendRefresh="eventMailSendRefresh = true"
      :isOpen="isOpen"
      @custumChange="closeModal"
      :dataToUse="getDataMailAppelCommercial"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MailVendeur from './mailVendeur.vue';
export default {
  props: {
    dataToUse: { required: true },
    selecteur: { default: 'id' }
  },
  data() {
    return {
      isOpen: false,
      eventMailSendRefresh: false
    };
  },
  computed: {
    ...mapGetters(['getDataMailAppelCommercial'])
  },
  methods: {
    ...mapActions([
      'fetchDataForMailAppelCommercial',
      'resetDataModalMailAppelPaiementCommercial'
    ]),
    openModal() {
      this.isOpen = true;
      this.fetchDataForMailAppelCommercial({
        data: this.dataToUse.map(item => item[this.selecteur])
      });
    },
    closeModal() {
      this.isOpen = false;
      if (this.eventMailSendRefresh == true) {
        this.$emit('eventMailSendRefreshTable');
      }
      this.eventMailSendRefresh = false;
      this.resetDataModalMailAppelPaiementCommercial();
    }
  },
  components: {
    MailVendeur
  }
};
</script>

<style scoped lang="scss">
.mailing-commercial {
}
</style>
